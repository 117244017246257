export enum PageVariant {
  AcceptTermsAndConditions = 'AcceptTermsAndConditions',
  ChangePassword = 'ChangePassword',
  ConfirmAccount = 'ConfirmAccount',
  ResetPassword = 'ResetPassword',
  CodeLogin = 'CodeLogin',
  ConfirmAccountAndSetPassword = 'ConfirmAccountAndSetPassword',
  Login = 'Login',
  Register = 'Register',
  Welcome = 'Welcome',
}

export type PageHeadlineProps = {
  page: PageVariant;
};
