import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax';

export type SignInProps = {
  login: string;
  password?: string;
  loginToken?: string;
};

const postSignIn =
  (http: HttpInstance) =>
  (requestProps: SignInProps): Promise<HttpClientResponse<null>> =>
    /**
     * @TODO: Temporary timeout value, should be removed after BE will be ready
     */
    http.post('/api/public/users/sign-in', JSON.stringify(requestProps), { timeout: 7000 });

export default postSignIn;
