import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax';

export type CSRFValidationData = {
  message: string;
  statusCode: number;
};

const getCSRFValidation = (http: HttpInstance) => (): Promise<HttpClientResponse<CSRFValidationData>> =>
  http.get('/api/public/csrf-validation');

export default getCSRFValidation;
