import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax';

export type SignUpNoPasswordData = {
  userId: number;
  emailOriginId: number;
  registrationSourceId: number;
  sessionId: string;
  creationDate: string;
};

type Agreements = {
  code: string;
  value: boolean;
};

export type SignUpNoPasswordProps = {
  email: string;
  agreements: Agreements[];
  returnUrl: string;
  emailOriginId: number | string;
};

const postSignUpNoPassword =
  (http: HttpInstance) =>
  (requestProps: SignUpNoPasswordProps): Promise<HttpClientResponse<SignUpNoPasswordData>> =>
    /**
     * @TODO: Temporary timeout value, should be removed after BE will be ready
     */
    http.post('/api/public/users/sign-up/no-password', JSON.stringify(requestProps), { timeout: 7000 });

export default postSignUpNoPassword;
