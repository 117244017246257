import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax';

import type { PasswordRuleCode } from 'src/utils/parse-password-rules';

type PasswordStrengthProperties = {
  password: string;
};

type PasswordStrengthResponseData = {
  rules: PasswordRuleCode[];
};

const postPasswordStrength =
  (http: HttpInstance) =>
  ({ password }: PasswordStrengthProperties): Promise<HttpClientResponse<PasswordStrengthResponseData>> =>
    http.post(`/api/public/password-strength`, JSON.stringify({ password }));

export default postPasswordStrength;
