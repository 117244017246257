import React from 'react';

import { PageVariant } from './PageHeadline.models';
import { type HeadlineProps } from 'src/app/components/Headline';

import {
  changePasswordCopy,
  confirmAccountAndSetPasswordCopy,
  confirmAccountCopy,
  loginCopy,
  registerCopy,
  resetPasswordCopy,
  termsAndConditionsCopy,
  welcomeCopy,
  codeLoginCopy,
} from './PageHeadline.constants';

export function getHeadlineProps(page: PageVariant, userEmail?: string, isApplicationProcess?: boolean) {
  const headlinePropsMap = new Map<PageVariant, HeadlineProps>([
    [PageVariant.AcceptTermsAndConditions, termsAndConditionsCopy],
    [PageVariant.ChangePassword, changePasswordCopy],
    [
      PageVariant.ConfirmAccount,
      {
        title: confirmAccountCopy.title,
        subtitle: (
          <>
            {confirmAccountCopy.confirmLinkSent} <strong>{userEmail}</strong>
          </>
        ),
        dataTest: confirmAccountCopy.datTest,
      },
    ],
    [
      PageVariant.ResetPassword,
      {
        title: resetPasswordCopy.title,
        subtitle: (
          <>
            {resetPasswordCopy.resetPasswordLinkSent} <strong>{userEmail}</strong>
          </>
        ),
        dataTest: resetPasswordCopy.dataTest,
      },
    ],
    [PageVariant.ConfirmAccountAndSetPassword, confirmAccountAndSetPasswordCopy],
    [
      PageVariant.CodeLogin,
      {
        title: codeLoginCopy.title,
        subtitle: (
          <>
            {codeLoginCopy.codeLoginLinkSent} <strong>{userEmail}</strong>
          </>
        ),
        dataTest: codeLoginCopy.dataTest,
      },
    ],
    [
      PageVariant.Login,
      {
        title: loginCopy.title,
        subtitle: (
          <>
            {loginCopy.enterYourPassword} <strong>{userEmail}</strong>
            {loginCopy.toLogIn}
          </>
        ),
        dataTest: loginCopy.dataTest,
      },
    ],
    [
      PageVariant.Register,
      isApplicationProcess
        ? {
            title: registerCopy.withApplicationProcess.title,
            subtitle: (
              <>
                {registerCopy.withApplicationProcess.subtitle}
                <br />
                <br />
                {registerCopy.withApplicationProcess.emailCopy} <strong>{userEmail}</strong>
              </>
            ),
            dataTest: registerCopy.withApplicationProcess.dataTest,
          }
        : {
            title: registerCopy.default.title,
            subtitle: (
              <>
                {registerCopy.default.subtitle} <strong>{userEmail}</strong>
              </>
            ),
            dataTest: registerCopy.default.dataTest,
          },
    ],
    [PageVariant.Welcome, isApplicationProcess ? welcomeCopy.withApplicationProcess : welcomeCopy.default],
  ]);

  return headlinePropsMap.get(page);
}
