import styles from './Headline.module.css';
import { type HeadlineProps } from './Headlines.models';

function Headline({ title, subtitle, dataTest }: HeadlineProps) {
  return (
    <h1 className={styles.title} data-test={dataTest}>
      {title}
      {subtitle ? <small className={styles.subtitle}>{subtitle}</small> : null}
    </h1>
  );
}

export default Headline;
