import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax';

export type UserIdData = {
  userId: number;
};

export type UserIdProps = { email: string };

const getUserId =
  (http: HttpInstance) =>
  ({ email }: UserIdProps): Promise<HttpClientResponse<UserIdData>> =>
    http.get(`/api/public/users?email=${encodeURIComponent(email)}`);

export default getUserId;
