export const offerMock = {
  offerId: 3501,
  offerURLName: 'erecruiter-kontrolki-v1-cierzpiety-pow-mragowski,oferta,3501',
  expirationDate: '2022-12-31T23:59:59Z',
  jobTitle: 'eRecruiter - kontrolki V1',
  displayEmployerName: '39,5',
  workplace: 'Cierzpięty (pow. mrągowski)',
  listing: {
    enabled: false,
    logo: '',
  },
  applyLink: 'https://example.pl/Offer.aspx?oid=3501&ccid=20056881',
  offerApplyType: {
    strefa: {
      formMode: 'multiple',
      formUrl: 'https://example.pl/Offer.aspx?oid=3501&ccid=20056881',
      multiApplying: [],
      optionalCv: false,
    },
    eRecruiter: null,
    ats: null,
  },
  offerViewLogo: 'https://logos-gpcdn.gp-dev.local/loga-firm/20056881/a6070000-5d83-0015-71bf-08d86c5bb3f2_280x280.jpg',
};
