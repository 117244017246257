export const welcomeCopy = {
  default: {
    title: 'Podaj adres e-mail',
    subtitle: 'aby się zalogować lub utworzyć konto',
    dataTest: 'title-welcome-form',
  },
  withApplicationProcess: {
    title: 'Żeby aplikować na to ogłoszenie, podaj adres e-mail',
    dataTest: 'title-welcome-form-with-application-process',
  },
};

export const termsAndConditionsCopy = {
  title: 'Jeszcze chwila...',
  subtitle: 'Twoje konto Pracuj.pl jest już prawie gotowe! Aby kontynuować, zaakceptuj regulamin.',
};

export const changePasswordCopy = {
  title: 'Utwórz hasło',
  dataTest: 'title-change-password-form',
};

export const confirmAccountCopy = {
  title: 'Sprawdź pocztę i aktywuj konto',
  confirmLinkSent: 'Wysłaliśmy link aktywacyjny na adres ',
  datTest: 'title-confirm-account',
};

export const resetPasswordCopy = {
  title: 'Sprawdź pocztę',
  resetPasswordLinkSent: 'Wysłaliśmy link do zmiany hasła na adres ',
  dataTest: 'title-reset-password-form',
};

export const codeLoginCopy = {
  title: 'Sprawdź pocztę i wpisz kod',
  codeLoginLinkSent: 'Wpisz kod weryfikacyjny, który wysłaliśmy na adres ',
  dataTest: 'title-code-login-form',
};

export const confirmAccountAndSetPasswordCopy = {
  title: 'Utwórz hasło',
  dataTest: 'title-confirm-account-and-set-password-form',
};

export const loginCopy = {
  title: 'Witaj ponownie',
  enterYourPassword: 'Wpisz hasło dla ',
  toLogIn: ', aby się zalogować',
  dataTest: 'title-login-form',
};

export const registerCopy = {
  default: {
    title: 'Utwórz hasło',
    subtitle: 'Aby kontynuować, utwórz hasło dla',
    dataTest: 'title-register-form',
  },
  withApplicationProcess: {
    title: 'Aby kontynuować aplikowanie, załóż konto w Pracuj.pl',
    subtitle:
      'Zarejestruj się, a dowiesz się czy Twoja aplikacja dotarła do pracodawcy! Wystarczy, że raz założysz konto, a aplikowanie na oferty pracy już zawsze będzie szybkie i proste.',
    emailCopy: 'Teraz utwórz hasło dla',
    dataTest: 'title-register-form-with-application-process',
  },
};
