'use client';

import { useAnalytics } from 'src/analytics';
import Headline from 'src/app/components/Headline';
import useLocalStorage from 'src/hooks/use-local-storage';
import { type PageHeadlineProps } from './PageHeadline.models';
import { getHeadlineProps } from './get-headline-props';

function PageHeadline({ page }: PageHeadlineProps) {
  const [userEmail] = useLocalStorage('userEmail', '');
  const { isApplicationProcess } = useAnalytics();
  const headlineProps = getHeadlineProps(page, userEmail, isApplicationProcess);

  return headlineProps ? <Headline {...headlineProps} /> : null;
}

export default PageHeadline;
