import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax';

export type ResetPasswordProps = { userEmail: string; returnUrl?: string | null };

const postSendResetPasswordMail =
  (http: HttpInstance) =>
  ({ userEmail, returnUrl }: ResetPasswordProps): Promise<HttpClientResponse<null>> =>
    http.post(`/api/public/emails/password-reset`, JSON.stringify({ userEmail, returnUrl }));

export default postSendResetPasswordMail;
