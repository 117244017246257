import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax';

export type PasswordData = {
  message: string;
  tokenExpired?: boolean;
};

export type PasswordProps = { userId: string; password: string; passwordToken: string; loginToken: string };

const postPassword =
  (http: HttpInstance) =>
  (requestProps: PasswordProps): Promise<HttpClientResponse<PasswordData>> =>
    http.post('/api/public/password', JSON.stringify(requestProps));

export default postPassword;
