import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax';

export type LoginPinProps = { userEmail: string };

const postSendLoginPinMail =
  (http: HttpInstance) =>
  ({ userEmail }: LoginPinProps): Promise<HttpClientResponse<null>> =>
    http.post(`/api/public/emails/login-pin`, JSON.stringify({ userEmail }));

export default postSendLoginPinMail;
